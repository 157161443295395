/* eslint-disable jsx-a11y/alt-text */
import "./App.css";
import { useState, useEffect } from "react";
import Masonry from "react-masonry-component";
import Header from "./Header";
import Footer from "./Footer";

function App() {
  function importAll(r) {
    return r.keys().map(r);
  }

  const [items, setitems] = useState(
    importAll(require.context("./images", false, /\.(png|jpe?g|svg)$/))
  );

  function shuffleArray(array) {
    array.sort(() => Math.random() - 0.5);
    return array;
  }

  useEffect(() => {
    setitems(shuffleArray(items.slice()));
  }, []);

  const masonryOptions = {
    itemSelector: ".image_collage",
    columnWidth: 120,
    fitWidth: true,
  };

  return (
    <div className="App">
      <Header></Header>
      <Masonry className="grid" elementType="ul" options={masonryOptions}>
        {items.map(function (item) {
          return (
            <li key={item.default} className="image_collage">
              <img src={item.default}></img>
            </li>
          );
        })}
      </Masonry>
      <Footer></Footer>
    </div>
  );
}

export default App;
