import React from "react";
import "./Header.css";
function Header() {
  return (
    <div className="header">
      <h1 className="app__title">PHILATELY</h1>
      <p className="app__title">History in stamps</p>
    </div>
  );
}
export default Header;
